import { React, useState, useEffect } from "react";
import './Team.css';
import headshots from "./TeamMembers";
import Typography from '@mui/material/Typography';
import Player from "../../components/player/Player"
import Widget from '../../components/widget/Widget';

function Team() {
    // Selected Player
    // const [selected, setSelected] = useState({name:"Player 1", role: "NPC", team: "development", imagePath: "https://static.vecteezy.com/system/resources/thumbnails/002/318/271/small/user-profile-icon-free-vector.jpg"});
    const [selected, setSelected] = useState(headshots[0]);

    const handleHeadshotClick = (headshot) => {
        setSelected(headshot);
    };

    // For Carousel
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    window.addEventListener('resize', () => {
        setViewportWidth(window.innerWidth);
    })

    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) {
                setItemsPerPage(2);
            } else if (window.innerWidth < 800) {
                setItemsPerPage(3);
            } else if (window.innerWidth < 1000) {
                setItemsPerPage(4);
            } else {
                setItemsPerPage(5);
            }
        };

        window.addEventListener('resize', handleResize);
    }, []);

    const handleNextClick = () => {
        if (currentIndex + itemsPerPage < headshots.length) {
            setCurrentIndex((prevIndex) => prevIndex + itemsPerPage);
        }
    };

    const handlePrevClick = () => {
        if (currentIndex > 1) {
            setCurrentIndex((prevIndex) => prevIndex - itemsPerPage);
        }
    };

    // Calculate the range of headshots to display
    const startIdx = currentIndex;
    const endIdx = currentIndex + itemsPerPage;
    const visibleHeadshots = headshots.slice(startIdx, endIdx);

    return (
        <div id="team" className="team">
            <div className="team-container">
                {/* Title */}
                <h1 className="section-title" style={{ textAlign: "left", marginBottom: "25px" }}>Meet The Team</h1>

                {/* Displaying Member */}
                <div className="team-content">
                    {/* <div className="headshot-container">
                        <SelectedCharacter selected={selected} />
                    </div> */}
                    <Player selected={selected} />

                    {/* Member Selection Grid */}
                    <div className="team-grid-container">
                        <div className="team-grid-subTitle">
                            <h1>
                                SELECT PLAYER
                            </h1>
                        </div>
                        <div className="team-grid">
                            {window.innerWidth > 1200 ?
                                <div className="grid">
                                    {headshots.map((headshot) => (
                                        <GridMember headshot={headshot} selected={selected} setSelected={setSelected} handleHeadshotClick={handleHeadshotClick} />
                                    ))}
                                </div>
                                :
                                <div>
                                    <div className="grid">
                                        <div
                                            className="team-buttons"
                                            onClick={handlePrevClick}
                                            disabled={currentIndex === 0}
                                            // style={{ marginRight: "0.5em" }}
                                        >
                                            &lt;
                                        </div>
                                        {visibleHeadshots.map((headshot) => (
                                            <GridMember
                                                key={headshot.id} // Make sure each item has a unique key
                                                headshot={headshot}
                                                selected={selected}
                                                setSelected={setSelected}
                                                handleHeadshotClick={handleHeadshotClick}
                                            />
                                        ))}
                                        <div
                                            className="team-buttons"
                                            onClick={handleNextClick}
                                            disabled={endIdx >= headshots.length}
                                            // style={{ marginLeft: "0.5em" }}
                                        >
                                            &gt;
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            {/* <Widget variant="StarYellow" style={{ left: 45, marginTop: "455px" }} /> */}
            {/* <Widget variant="CirclePink" style={{ right: 165, marginTop: "65px" }} /> */}
            {/* <Widget variant="SquareBlue" style={{ right: 25, marginTop: "750px" }} /> */}

            <div style={{ position: "absolute", zIndex: "1", width: "25%", height: "25%", background: "rgba(106, 170, 255, 0.40)", filter: "blur(300px)", left: "205px", marginTop: "150px" }}>
            </div>
        </div>
    );
}

const GridMember = (props) => {
    const { headshot, selected, setSelected, handleHeadshotClick } = props;

    return (
        <div
            key={headshot.id}
            onClick={() => handleHeadshotClick(headshot)}
            className={`member ${headshot === selected ? 'selected' : ''}`}
        >
            {headshot === selected && <div className="selected-label">1P</div>}
            <img className={`member-photo ${headshot === selected ? 'selected' : ''}`} src={headshot.imagePath} />
            <p className={`member-name ${headshot === selected ? 'selected' : ''}`}>{headshot.name}</p>
        </div>
    )
}

const SelectedCharacter = (props) => {
    const { selected } = props;

    return (
        <div className="character-card">
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="383" height="498" viewBox="0 0 383 498" fill="rgba(255, 255, 255, 0.10)">
                <path d="M0 5C0 2.23858 2.23858 0 5 0H199.16H328.929C330.255 0 331.527 0.526784 332.464 1.46447L381.536 50.5355C382.473 51.4732 383 52.745 383 54.0711V493C383 495.761 380.761 498 378 498H199.16H99.58H4.99999C2.23857 498 0 495.761 0 493V446V435.75V373.5V249V5Z" fill="#303030" fill-opacity="0.3" />
                <foreignObject x="0" y="30" width="100%" height="100%">
                    
                </ foreignObject>
            </svg> */}
            {/* <div className="character-card"> */}
            {/* Image */}
            <div style={{ zIndex: 1 }}>
                {selected.imagePath !== "" ? <img className="headshot" src={selected.imagePath} alt="Headshot" /> : <div className="headshot"></div>}
            </div>
            {/* Info */}
            <div className="selected-info" >
                <Typography id="selected-name">{selected.name}</Typography>
                <Typography id="selected-role">{selected.role}</Typography>
            </div>
            {/* Extra Info */}
            <Typography id="selected-extra" sx={{ marginTop: "1rem", }}>{selected.role}</Typography>
            {/* </div> */}
        </ div>
    )
}

export default Team;