import { React, useState, useEffect } from "react";
import './Stats.css';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Fade from 'react-reveal/Fade';
import { Element } from "react-scroll";
import Widget from '../../components/widget/Widget';

function Stats() {

    // Stats
    const statistics = [
        {
            logo: 'favourite',
            name: "Hackers",
            num: "300+",
        },
        {
            logo: 'time',
            name: "Hours",
            num: "48",
        },
        {
            logo: 'prize',
            name: "In Prizes",
            num: "$20k+",
        },
    ];

    // Scroll component into view
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.7,
        };

        const targetElement = document.getElementById("statsComponent");

        const observer = new IntersectionObserver(([entry]) => {
            // Update the state based on visibility status
            setIsVisible(entry.isIntersecting);
        }, options);

        // Start observing the target element
        if (targetElement) {
            observer.observe(targetElement);
        }

        // Clean up the observer when component unmounts
        return () => {
            if (targetElement) {
                observer.unobserve(targetElement);
            }
        };
    }, []);
    // 150 320
    // 702
    // y = 15, y = 0
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [viewBox, setViewBox] = useState("0 0 702 150");
    const [height, setHeight] = useState("300");
    const [yOffset, setYOffset] = useState("35");
    useEffect(() => {
        if (isMobile) {
            setViewBox("0 0 320 450");
            setHeight("400");
            setYOffset("55");
        } else {
            setViewBox("0 0 702 150");
            setHeight("300");
            setYOffset("35");
        }
    }, [isMobile]);

    return (
        <div className="stats">
            <Element id="statsComponent">
                <div className="stats-container">
                    <Fade when={isVisible}>
                        {statistics.map(({ logo, num, name }) => (
                            <div className="facts">
                                <div className="facts-logo">
                                    {logo === 'favourite' && <FavoriteIcon className="facts-icon" style={{ fontSize: '4rem', color: '#9173D1' }} />}
                                    {logo === 'time' && <HourglassFullIcon className="facts-icon" style={{ fontSize: '4rem', color: '#9173D1' }} />}
                                    {logo === 'prize' && <EmojiEventsIcon className="facts-icon" style={{ fontSize: '4rem', color: '#9173D1' }} />}
                                </div>

                                <div className="facts-description">
                                    <div className="facts-num">
                                        <p className="pink">{num}</p>
                                    </div>

                                    <div className="facts-text">
                                        {
                                            name === "hours" ?
                                                <p className="pink">{name}&ensp;&ensp;</p> :
                                                <p className="pink">{name}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Fade>
                </div>
                {/* <Widget variant="StarPink" style={{ right: 25, }} /> */}
                {/* <Widget variant="CircleBlue" style={{ left: 200, marginTop: "155px", }} /> */}
                {/* <Widget variant="StarYellow" style={{ marginLeft: "175px", marginTop: "-125px", }} /> */}

            </Element>
        </div>
    );
}

export default Stats;
