import Racoon from "../../assets/headshots/racoon.PNG"
import Marjan from "../../assets/headshots/20221202_183516 - Marjan Ahmed.jpg"
import Arsalan from "../../assets/headshots/Arsalan Khalid.jpg"
import Indu from "../../assets/headshots/Indu Das.jpg"
import Iyana from "../../assets/headshots/Iyana Alam.jpeg"
import Jason_K from "../../assets/headshots/Jason_Khuu.png"
import Jason_N from "../../assets/headshots/Jason_Nguyen.jpeg"
import Massi from "../../assets/headshots/Massi Ahmed.jpeg"
import Nikita from "../../assets/headshots/Nikita Berezyuk.jpg"
import Paige from "../../assets/headshots/Paige Tsang.jpg"
import Rishie from "../../assets/headshots/Rishie.JPG"
import Ross from "../../assets/headshots/RossEnriquez.jpeg"
import Sareena from "../../assets/headshots/sareenagill.jpg"
import Zafrin from "../../assets/headshots/Zafrin Rahman.JPG"

const headshots = [
    {
        name: "Marjan",
        role: "Co-Chair",
        team: "",
        imagePath: Marjan
    },
    {
        name: "Arsalan",
        role: "Associate",
        team: "Operations",
        imagePath: Arsalan
    },
    {
        name: "Indu",
        role: "Vice President",
        team: "Sponsorship",
        imagePath: Indu
    },
    {
        name: "Iyana",
        role: "Vice President",
        team: "Communications",
        imagePath: Iyana
    },
    {
        name: "Jason",
        role: "Vice President",
        team: "Development",
        imagePath: Jason_K
    },
    {
        name: "Jason",
        role: "Associate",
        team: "Communications",
        imagePath: Jason_N
    },
    {
        name: "Massi",
        role: "Co-Chair",
        team: "",
        imagePath: Massi
    },
    {
        name: "Nikita",
        role: "Associate",
        team: "Development",
        imagePath: Nikita
    },
    {
        name: "Paige",
        role: "Associate",
        team: "Operations Development",
        imagePath: Paige
    },
    {
        name: "Rishie",
        role: "Associate",
        team: "Operations",
        imagePath: Rishie
    },
    {
        name: "Ross",
        role: "Associate",
        team: "Development",
        imagePath: Ross
    },
    {
        name: "Sareena",
        role: "Vice President",
        team: "Operations",
        imagePath: Sareena
    },
    {
        name: "Zafrin",
        role: "Associate",
        team: "Sponsorship",
        imagePath: Zafrin
    },
    {
        name: "Racoon",
        role: "Mascot",
        team: "",
        imagePath: Racoon
    },
]

export default headshots;