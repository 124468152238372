import { React, useEffect, useState } from "react";
import './About.css';
import Racoon1 from "../../assets/graphics/racoon1.png"
import Racoon4 from "../../assets/graphics/racoon4.png"
import Player from "../../components/player/Player"
import Fade from 'react-reveal/Fade';
import { Element } from "react-scroll";
import Widget from '../../components/widget/Widget';

function About() {
    const filler = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    const paragraph1 = "MetHacks is Toronto Metropolitan University’s student-run hackathon, an event open to anyone from around the globe! It's an opportunity to let your imagination run wild and meet like-minded individuals to allow your dreams to come to life. We invite you to join us in an array of stellar workshops, exciting events and a chance to gain incredible mentorship opportunities. Join us in our 8th year and redefine, innovate and design something truly inspiring."
    const paragraph2 = "Our hackathon is an immersive experience unlike any other. Whether you're an experienced pro looking to break new ground or a curious newbie exploring unknown territory, all skill levels are welcome to participate. At MetHacks, we provide students with the chance to interact with industry professionals, develop new skills and collaborate with fellow innovators to bring their ideas to reality. Brace yourself, you'll be in for an amazing experience!    "

    // Scroll component into view
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const isMobile = window.innerWidth <= 768;
        const rootMargin = isMobile ? "850px" : "200px";
        const threshold = isMobile ? 0.8 : 0.7;

        const options = {
            root: null,
            rootMargin: rootMargin,
            threshold: threshold,
        };

        const targetElement = document.getElementById("aboutComponentFade");

        const observer = new IntersectionObserver(([entry]) => {
            // Update the state based on visibility status
            setIsVisible(entry.isIntersecting);
        }, options);

        // Start observing the target element
        if (targetElement) {
            observer.observe(targetElement);
        }

        // Clean up the observer when component unmounts
        return () => {
            if (targetElement) {
                observer.unobserve(targetElement);
            }
        };
    }, []);

    return (
        <div id="about" className="about">
            <div className="about-container">
                <div class="border-image-container" id="aboutComponentFade">
                    {/* Title */}
                    <h1 className="section-title" style={{ textAlign: "left", marginLeft: 0, }}>About Us</h1>

                    {/* Sub Title / Hackathon Overview */}
                    <div className="about-content">
                        <Element id="aboutComponent">
                            <Fade left when={isVisible}>
                                {/* <div className="about-details"> */}
                                <div>
                                    <h1 className="about-subtitles" style={{ textAlign: "center", marginBottom: 25, color: "#a2f0fc" }}>What is Met Hacks?</h1>
                                    <h3 className="about-text">
                                        {paragraph1}
                                    </h3>
                                </div>

                                <div id="about-graphics-middle" style={{ background: `url(${Racoon1}) center/cover` }}>
                                </div>

                                <div style={{ marginBottom: "25px", marginTop: "50px", }}>
                                    <h1 className="about-subtitles" style={{ textAlign: "center", marginBottom: 25, color: "#dd95ed" }}>Why Attend?</h1>
                                    <h3 className="about-text">
                                        {paragraph2}
                                    </h3>
                                </div>

                            </Fade>
                        </Element>

                        <Element id="aboutComponent" style={{ marginTop: "10px", alignItems: "center", }}>
                            <Fade right when={isVisible}>
                                {/* Graphics */}
                                <div className="about-graphics" id="about-graphics-two" style={{ background: `url(${Racoon1}) center/cover` }}>
                                </div>
                            </Fade>
                        </Element>
                    </div>
                </div>
                {/* Graphics */}
                {/* <div className="about-graphics" style={{ background: `url(${Racoon1}) center/cover`, }}>
                </div> */}
            </div>
            {/* <Widget variant="SquarePink" style={{ right: 125, marginTop: "-100px" }} /> */}
            {/* <Widget variant="CircleYellow" style={{ right: 65, marginTop: "425px" }} /> */}
            {/* <Widget variant="StarBlue" style={{ left: 95, marginTop: "55px" }} /> */}

        </div>
    );
}

export default About;