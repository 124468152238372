import { React } from "react";
import './FooterGraphic.css';
import FooterGraphic2 from '../../assets/graphics/grid.svg';
// import FooterGraphic2 from '../../assets/backgrounds/group_11.png';

function FooterGraphic() {

    return (
        <div className="footergraphic">
            <div className="footergraphic-container">
            </div>
        </div>
    );
}

export default FooterGraphic;
