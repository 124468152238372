import { React, useState, useEffect } from "react";
import './MiddleGraphic.css';
import MiddleGraphic2 from '../../assets/backgrounds/Group.png';
import Fade from 'react-reveal/Fade';
import ColorButton from "../buttons/ColorButton";
import GlitchButton from "../buttons/GlitchButton";
import AngleButton from "../buttons/AngleButton";
import Racoon4 from "../../assets/graphics/racoon4.png"
import Wave from "../../assets/graphics/wave.png"
// import Wave from "../../assets/graphics/htn.svg"

function MiddleGraphic() {

    // Scroll component into view
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        };

        const targetElement = document.getElementById("middlegraphicComponent");

        const observer = new IntersectionObserver(([entry]) => {
            // Update the state based on visibility status
            setIsVisible(entry.isIntersecting);
        }, options);

        // Start observing the target element
        if (targetElement) {
            observer.observe(targetElement);
        }

        // Clean up the observer when component unmounts
        return () => {
            if (targetElement) {
                observer.unobserve(targetElement);
            }
        };
    }, []);

    return (
        <div className="middlegraphic">
            <div className="middlegraphic-graphics" style={{ background: `url(${Wave}) center/cover`, }}>
            </div>
            <div className="middlegraphic-container" id="middlegraphicComponent">
                <Fade top when={isVisible}>
                    <div className="middlegraphic-content">
                        <h1 className="middlegraphic-title">Embrace the Challenge</h1>
                        <img className="middlegraphic-gif" src={Racoon4} alt="Animated GIF" />
                    </div>
                    {/* <GlitchButton onHandle={() => { console.log("Hi") }} text={"Apply Here"} /> */}
                    <AngleButton onHandle={() => { console.log("Hi") }} text={"Apply Here"} variant={1} fontSize={"2rem"} />
                </Fade>
                <div style={{ position: "absolute", zIndex: "1", width: "25%", height: "25%", background: "rgba(106, 170, 255, 0.40)", filter: "blur(300px)", left: "150px", marginTop: "0px" }}>
                </div>
                <div style={{ position: "absolute", zIndex: "0", width: "25%", height: "25%", background: "rgba(106, 170, 255, 0.40)", filter: "blur(300px)", right: "25px", marginTop: "50px" }}>
                </div>
            </div>
            {/* <div className="middlegraphic-graphics" style={{ background: `url(${Wave}) center/cover`, }}>
            </div> */}
        </div>
    );
}

export default MiddleGraphic;
