import { React } from "react";
import './AngleButton.css';

function AngleButton(props) {
    const { variant, onHandle, text, fontSize } = props;
    const filterId = `filter${variant}_d_20_1219`;

    return (
        <>
            {variant == 1 ?
                <div className="anglebutton-container" onClick={onHandle}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="260" height="100" viewBox="0 0 350 100" fill="none">
                        <g filter={`url(#${filterId})`}>
                            <path d="M8 8H120H175H230L280 50V90H120H70H40L8 60V8Z" fill="#9173D1" />
                        </g>
                        <defs>
                            <filter id="filter0_d_20_1219" x="0" y="0" width="260" height="100" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset />
                                <feGaussianBlur stdDeviation="4" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.568627 0 0 0 0 0.45098 0 0 0 0 0.819608 0 0 0 1 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20_1219" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_20_1219" result="shape" />
                            </filter>
                        </defs>
                        <foreignObject x="-30" y="35" width="100%" height="100%">
                            <div>
                                <p className="anglebutton-text" style={{ fontSize: fontSize}}>{text}</p>
                            </div>
                        </foreignObject>
                    </svg>
                </div>
                :
                <div className="anglebutton-container" onClick={onHandle}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="260" height="100" viewBox="0 0 290 100" fill="none">
                        <g filter={`url(#${filterId})`}>
                            <path d="M8 8H120H175H230L280 50V90H120H70H40L8 60V8Z" stroke="white" stroke-width="2"/>
                        </g>
                        <foreignObject x="0" y="35" width="100%" height="100%">
                            <div>
                                <p className="anglebutton-text" style={{color: "white", fontSize: fontSize}}>{text}</p>
                            </div>
                        </foreignObject>
                    </svg>
                </div>
            }
        </>
    );
}

export default AngleButton;
