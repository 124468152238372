import './App.css';
import { BrowserRouter } from "react-router-dom";

import Navbar from "./pages/navbar/Navbar"
import Hero from "./pages/hero/Hero"
import About from "./pages/about/About"
import FAQ from "./pages/faq/FAQ"
import MiddleGraphic from "./components/middleGraphic/MiddleGraphic"
import Team from "./pages/team/Team"
import Sponsors from "./pages/sponsors/Sponsors"
import Stats from "./components/stats/Stats";
import Carousel from "./components/carousel/Carousel";
import FooterGraphic from "./components/footerGraphic/FooterGraphic";
import Footer from "./pages/footer/Footer"
// import Navbar from "./pages/ /"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Hero />
        <About />
        <Carousel />
        <Stats />
        <Team />
        {/* <MiddleGraphic /> */}
        <FAQ />
        <Sponsors />
        <FooterGraphic />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
