import { React, useState, useEffect, useRef } from "react";
import './Sponsors.css';
import Grid from '@mui/material/Grid';
import Racoon2 from "../../assets/graphics/racoon2.PNG";
import { Button } from '@mui/material';
import Fade from 'react-reveal/Fade';
import { Element } from "react-scroll";
import ReactRotatingText from 'react-rotating-text';

// Sponsors
import Balsamiq from '../../assets/sponsors/Balsamiq.png';
import Chatime from '../../assets/sponsors/Chatime.png';
import Cohere from '../../assets/sponsors/cohere.png';
import TMUECGEng from '../../assets/sponsors/Department of ECB Engineering.jpg';
import TMUScience from '../../assets/sponsors/Faculty of Science.png';
import TMUCS from '../../assets/sponsors/TMUCS.webp';
import TMSU from '../../assets/sponsors/TMSU.webp';
import IBZ from '../../assets/sponsors/IBZ.png';
import MLH from '../../assets/sponsors/MLH.png';
import SIF from '../../assets/sponsors/SIF.jpg';
import ITM from '../../assets/sponsors/itm.jpg';
import StandOutStickers from '../../assets/sponsors/StandOutStickers.png';
import TD from '../../assets/sponsors/TD.jpg';
import TD2 from '../../assets/sponsors/TD.png';
import USSTM from '../../assets/sponsors/Undergraduate Science Society.png';
import XRPL from '../../assets/sponsors/XRPL (2).png';
import FDM from '../../assets/sponsors/FDM.svg';
import AngleButton from "../../components/buttons/AngleButton";
import SponsorPackage from "../../assets/MetHacks_2023_Sponsorship.pdf"

function Sponsors() {
    // Open Sponsors PDF
    const openPDF = () => {
        window.open(SponsorPackage, '_blank'); // Opens the PDF in a new tab
    };

    return (
        <div className="sponsors" id="sponsors">
            <div className="sponsors-container">
                {/* Title */}
                <h1 className="section-title" style={{ textAlign: "left", marginBottom: "1rem", }}>Our Previous Sponsors</h1>
                {/* Sponsor Details */}
                <div className="sponsor-text" style={{ paddingTop: "4rem", paddingBottom: "2rem", }}>By sponsoring Met Hacks, your contribution will empower students of all experience levels and backgrounds with the mentorship and resources to create projects they’re passionate about.
                    <br /><br />
                    Engage with the next generation of innovators and help us unite over 300 emerging {" "}

                    {/* <span ref={el} style={{ color: "#a2f0fc" }}></span> */}
                    <ReactRotatingText style={{ color: "#a2f0fc" }} items={["developers", "designers", "builders",]} />

                .</div>
                {/* Sponsor Us Button */}
                <div className="sponsor-text-parent" style={{ marginBottom: "4rem", paddingTop: "1rem", paddingBottom: "1rem", display: "flex", justifyContent: "space-between", flexWrap: "wrap", rowGap: 35 }}>
                    <p className="sponsor-text" >Interested in sponsoring? Email us at <a href="mailto:methacks@torontomu.ca" className="sponsor-text">methacks@torontomu.ca</a></p>
                    <div className="sponsor-button" >
                        <AngleButton onHandle={openPDF} text={"Become a Sponsor"} variant={2} fontSize={"22px"} />
                    </div>
                </div>

                <Grid container rowGap={2} columnGap={1} className="sponsors-grid" sx={{ marginTop: "7rem", marginLeft: "0px", marginRight: "0px", alignItems: "stretch" }}>
                    {/* <Grid item xs={12} sx={{ marginTop: -2, marginBottom: 5, }}>
                        <h1>Thank you sponsors for supporting Met Hacks</h1>
                    </Grid> */}
                    <Grid item xs={12} sx={{ justifyContent: "center", }}>
                        <div className="sponsor-image-parent large" onClick={() => window.open("www.td.com", "_blank")}>
                            <img className="sponsor-image-large" src={TD2} alt="TD" />
                        </div>
                    </Grid>
                    <Grid item container rowGap={2} columnGap={2} xs={12} sx={{ justifyContent: "center", }}>
                        <Grid item md={4} sx={{ justifyContent: "center", }}>
                            <div className="sponsor-image-parent medium" onClick={() => window.open("https://ripple.com/xrp/", "_blank")}>
                                <img className="sponsor-image" src={XRPL} alt="XRPL" />
                            </div>
                        </Grid>
                        <Grid item md={4} sx={{ justifyContent: "center", }}>
                            <div className="sponsor-image-parent medium" onClick={() => window.open("https://www.fdmgroup.com/", "_blank")}>
                                <img className="sponsor-image" src={FDM} alt="FDM" />
                            </ div>
                        </Grid>
                    </Grid>
                    {/* <Grid item container rowGap={2} columnGap={2} xs={12} sx={{ justifyContent: "center", }}>
                        <Grid item md={4} sx={{ justifyContent: "center", }}>
                            <div className="sponsor-image-parent medium" onClick={() => window.open("https://www.ecb.torontomu.ca/", "_blank")}>
                                <img className="sponsor-image" src={TMUECGEng} alt="TMUECGEng" />
                            </ div>
                        </Grid>
                        <Grid item md={4} sx={{ justifyContent: "center", }}>
                            <div className="sponsor-image-parent medium" onClick={() => window.open("https://www.torontomu.ca/science/", "_blank")}>
                                <img className="sponsor-image" src={TMUScience} alt="TMUScience" />
                            </ div>
                        </Grid>
                    </Grid> */}
                    <Grid item md={3} sx={{ justifyContent: "center" }}>
                        <div className="sponsor-image-parent" onClick={() => window.open("https://www.innovationboostzone.com/", "_blank")}>
                            <img className="sponsor-image" src={IBZ} alt="IBZ" />
                        </ div>
                    </Grid>
                    <Grid item md={3} sx={{ justifyContent: "center" }}>
                        <div className="sponsor-image-parent" onClick={() => window.open("https://mlh.io/", "_blank")}>
                            <img className="sponsor-image" src={MLH} alt="MLH" />
                        </ div>
                    </Grid>
                    <Grid item md={3} sx={{ justifyContent: "center" }}>
                        <div className="sponsor-image-parent" onClick={() => window.open("https://www.torontomu.ca/science/", "_blank")}>
                            <img className="sponsor-image" src={TMUScience} alt="TMUScience" />
                        </ div>
                    </Grid>
                    <Grid item md={3} sx={{ justifyContent: "center", }}>
                        <div className="sponsor-image-parent" onClick={() => window.open("https://www.ecb.torontomu.ca/", "_blank")}>
                            <img className="sponsor-image" src={TMUECGEng} alt="TMUECGEng" />
                        </ div>
                    </Grid>
                    <Grid item md={3} sx={{ justifyContent: "center", }}>
                        <div className="sponsor-image-parent" onClick={() => window.open("https://www.torontomu.ca/cs/", "_blank")}>
                            <img className="sponsor-image" src={TMUCS} alt="TMUCS" />
                        </ div>
                    </Grid>
                    <Grid item md={3} sx={{ justifyContent: "center", }}>
                        <div className="sponsor-image-parent" onClick={() => window.open("https://www.torontomu.ca/student-life-and-learning/programs/student-initiativefund/", "_blank")}>
                            <img className="sponsor-image" src={SIF} alt="SIF" />
                        </ div>
                    </Grid>
                    <Grid item md={3} sx={{ justifyContent: "center", }}>
                        <div className="sponsor-image-parent" onClick={() => window.open("https://yourtmsu.ca/", "_blank")}>
                            <img className="sponsor-image" src={TMSU} alt="TMSU" />
                        </div>
                    </Grid>
                    <Grid item md={3} sx={{ justifyContent: "center", }}>
                        <div className="sponsor-image-parent" onClick={() => window.open("https://www.usstm.ca/", "_blank")}>
                            <img className="sponsor-image" src={USSTM} alt="USSTM" />
                        </div>
                    </Grid>
                    <Grid item md={3} sx={{ justifyContent: "center", }}>
                        <div className="sponsor-image-parent" onClick={() => window.open("https://www.torontomu.ca/information-technology-management/", "_blank")}>
                            <img className="sponsor-image" src={ITM} alt="ITM" />
                        </div>
                    </Grid>
                </Grid>
                {/* Graphics */}
                <div className="sponsors-graphics" style={{ background: `url(${Racoon2}) center/cover` }}>
                </div>
            </div>
            <div style={{ position: "absolute", zIndex: "1", width: "25%", height: "25%", background: "rgba(106, 170, 255, 0.40)", filter: "blur(300px)", left: "55px", marginTop: "150px" }}>
            </div>
            <div style={{ position: "absolute", zIndex: "1", width: "25%", height: "25%", background: "rgba(106, 170, 255, 0.40)", filter: "blur(300px)", right: "15px", marginTop: "1150px" }}>
            </div>
        </div>
    );
}

export default Sponsors;