import { React, useState, useEffect } from "react";
import './Hero.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faDiscord, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { Button } from '@mui/material';
import Landing from "../../assets/graphics/landing.png"
// import PCLanding from "../../assets/graphics/pclanding.png"
// import MobileLanding from "../../assets/graphics/mobilelanding.png"
import PCLanding from "../../assets/graphics/pclanding.webp"
import MobileLanding from "../../assets/graphics/mobilelanding.webp"
import AngleButton from "../../components/buttons/AngleButton";
import GlitchButton from "../../components/buttons/GlitchButton";

function Hero() {
    const [graphicSize, setGraphicSize] = useState(PCLanding);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 750) {
                setGraphicSize(MobileLanding);
            } else {
                setGraphicSize(PCLanding);
            }
        };

        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <div id="hero" className="hero">
            <div className="hero-container" style={{ background: `url(${graphicSize}) center/cover` }}>
                {/* Titles */}
                <div className="hero-main">
                    <h1 className="hero-title">MetHacks 2025</h1>
                    <h2 className="hero-subTitle">May Xth - May Xth</h2>
                    {/* <div className="hero-button"> */}
                        {/* <Button variant="contained">Apply Here</Button> */}
                        {/* <GlitchButton onHandle={() => { console.log("Hi") }} text={"Apply Here"} /> */}
                        {/* <AngleButton onHandle={() => { window.location.href = "https://app.methacks.ca" }} text={"Apply Here"} variant={1} fontSize={"1.5em"} /> */}
                    {/* </div> */}
                    <h3 className="hero-info">Toronto Metropolitan University's 8th Official Hackathon</h3>
                </div>
            </div>
        </div>
    );
}

export default Hero;
