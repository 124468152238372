import React, { useState } from "react";
import { Box, Heading } from "@chakra-ui/react";
import "./FAQ.css";
import Grid from '@mui/material/Grid';
import Racoon3 from "../../assets/graphics/racoon3.PNG";
import Widget from '../../components/widget/Widget';

function FAQ() {
  const [faqs, setFaqs] = useState([
    {
      question: "Who can attend?",
      answer:
        "If you are a student enrolled in any high school or post secondary institution, you are eligible to apply and attend MetHacks.",
      isOpen: false
    },
    {
      question: "When can I sign up?",
      answer: "Hacker applications for MetHacks 2023 open on April 2nd, 2023 at 6:00 PM EST. Stay tuned for our App Portal to check your application status and check out our social media for round acceptance announcements!",
      isOpen: false
    },
    {
      question: "How much does it cost?",
      answer: "Admission to MetHacks is completely free!",
      isOpen: false
    },
    {
      question: "Where is the event located?",
      answer:
        "MetHacks will be taking place in the Ted Rogers School of Management (TRSM) on 55 Dundas Street West, Toronto, ON M5G 2C5.",
      isOpen: false
    },
    {
      question: "How do I get to the event?",
      answer: "Instructions on how to enter TRSM and find the room location will be sent out to hackers closer to the event.",
      isOpen: false
    },
    {
      question: "Am I able to stay overnight to continue on my hack?",
      answer: "Yes, you can. More information will be released closer to the hackathon.",
      isOpen: false
    },
    {
      question: "Do I need prior technical and coding skills to attend?",
      answer:
        "No, prior coding or technical experience is not necessary. MetHacks is a fun learning experience where we’ll be hosting beginner workshops, talks and mentors to guide you through projects.",
      isOpen: false
    },
    {
      question: "Can I start working on my hack beforehand?",
      answer: "No, we do not allow participants to work on pre-existing projects. MetHacks hackers are required to start and complete their project during the hackathon. However, you are welcome to familiarize yourself with specific tools and technologies beforehand!",
      isOpen: false
    },
    {
      question: "How does judging work?",
      answer: "Our panel of judges come from a variety of backgrounds and fields. More specific judging criteria will be sent to you closer to the hackathon. The top projects will get a chance to show their recorded demos in front of all of MetHacks during the closing ceremony.",
      isOpen: false
    },
    {
      question: "What about hardware hacks?",
      answer: "You are welcome to create a hardware hack, but we ask that you respect COVID-19 restrictions while doing so. More information will be released closer to the hackathon.",
      isOpen: false
    },
    {
      question: "How big can teams be?",
      answer: "A single team can consist of 1 to 4 people. If you are looking to find other hackers to compete alongside, we encourage you to join our Team Formation session at the start of the hackathon on the MetHacks 2023 Discord!",
      isOpen: false
    },
    {
      question: "Still have questions?",
      answer: "Reach out to us at communications@ruhacks.com or our social media, and we’ll get back to you ASAP!",
      isOpen: false
    }
  ]);

  const toggleFAQ = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) => {
        if (i === index) {
          return { ...faq, isOpen: !faq.isOpen };
        }
        return faq;
      })
    );
  };

  return (
    <div id="faq" className="faq-head">
      <div className="faq-container">
        <div className="faq-title">
          {/* <h1 className="section-title" style={{ textAlign: "left", marginBottom: "2rem", color: "white", }}>FREQUENTLY ASKED</h1> */}
          <h1 className="section-title" style={{ textAlign: "left", marginBottom: "2rem", color: "white", }}>FAQ</h1>
          {/* Graphics */}
          <div className="faq-graphics" style={{ background: `url(${Racoon3}) center/cover` }}>
          </div>
        </div>
        {/* Column 1 */}
        <div className="faqs-parent">
          <div style={{ justifyContent: "center", alignItems: "flex-start" }} className="faqs">
            {faqs.slice(0, Math.ceil(faqs.length / 2)).map((faq, index) => (
              <div
                className={`cursor-pointer faq ${faq.isOpen ? "open" : ""}`}
                key={index}
                onClick={() => toggleFAQ(index)}
              >
                <div className="faq-question">{faq.question}</div>
                {faq.isOpen && <div className="faq-answer">{faq.answer}</div>}
              </div>
            ))}
          </div>
          {/* Column 2 */}
          <div style={{ justifyContent: "center", alignItems: "flex-start" }} className="faqs">
            {faqs.slice(Math.ceil(faqs.length / 2), faqs.length).map((faq, index) => (
              <div
                className={`cursor-pointer faq ${faq.isOpen ? "open" : ""}`}
                key={index + Math.ceil(faqs.length / 2)}
                onClick={() => toggleFAQ(index + Math.ceil(faqs.length / 2))}
              >
                <div className="faq-question">{faq.question}</div>
                {faq.isOpen && <div className="faq-answer">{faq.answer}</div>}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", zIndex: "1", width: "25%", height: "25%", background: "rgba(106, 170, 255, 0.40)", filter: "blur(300px)", right: "55px", }}>
      </div>
      {/* <Widget variant="SquarePink" style={{ left: 75, marginTop: "155px" }} /> */}
      {/* <Widget variant="CircleYellow" style={{ right: 155, marginTop: "555px" }} /> */}

    </div>
  );
}

export default FAQ;