import { React } from "react";
// import MetHacks from '../../assets/logos/methacks.png';
import MetHacks from '../../assets/logos/methacks_long_white.PNG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { faTwitter, faInstagram, faDiscord, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import './Footer.css';

function Footer() {

    return (
        <div id="footer" className="footer">
            {/* Footer */}
            <div className="footer-container">
                <Link
                    className="button-arrow bounce"
                    to="/"
                    onClick={() => {
                        let hero = document.getElementById("hero");
                        hero &&
                            hero.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                            });
                    }}
                >
                    <FontAwesomeIcon icon={faChevronUp} />
                </Link>

                {/* Title */}
                <div className="footer-rowOne">
                    {/* <h1 style={{ textAlign: "left", }}>Met Hacks</h1> */}
                    <img src={MetHacks} style={{ width: "225px", }} alt="MetHacks LOGO" />
                    <p id="cc">© MetHacks 2024</p>
                    <a id="email" href="mailto:methacks@torontomu.ca">methacks@torontomu.ca</a>
                    <a id="conduct" href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf">MLH Code of Conduct</a>
                </div>

                {/* Icons */}
                <div className="hero-icons">
                    <a className="social-icon" href="https://twitter.com/methacks_" id="twitter-icon"><FontAwesomeIcon icon={faTwitter} size="lg" /></a>
                    <a className="social-icon" href="https://www.instagram.com/methacks_/" id="insta-icon"><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
                    <a className="social-icon" href="https://www.linkedin.com/company/methacks/" id="linkedin-icon"><FontAwesomeIcon icon={faLinkedin} size="lg" /></a>
                    <a className="social-icon" href="https://www.facebook.com/methacks/?paipv=0&eav=AfayAK2uI9owsAxadMZVWQJ4QOolEGUDMf0B-7UjLSUI9nn9TnTi48ELOQog3X_clfw&_rdr" id="fb-icon" ><FontAwesomeIcon icon={faFacebook} size="lg" /></a>
                </div>

            </div>
        </div>
    );
}

export default Footer;