import { React, useState, useEffect } from "react";
import './Player.css';
import Typography from '@mui/material/Typography';
import MetHacksLogo from "../../assets/logos/methacks_small_white.PNG";
import CommsLogo from "../../assets/icons/comm.png";
import CoChairLogo from "../../assets/icons/crown.png";
import DevLogo from "../../assets/icons/dev.webp";
import OpsLogo from "../../assets/icons/ops.png";
import SponsorLogo from "../../assets/icons/spons.png";

function Player(props) {
    const { selected } = props;

    const [teamIcon, setTeamIcon] = useState("")

    useEffect(() => {
        if (selected.team === "development") {
            setTeamIcon(DevLogo);
        }
        else if (selected.team === "communications") {
            setTeamIcon(CommsLogo);
        }
        else if (selected.team === "co-chair") {
            setTeamIcon(CoChairLogo);
        }
        else if (selected.team === "sponsors") {
            setTeamIcon(SponsorLogo);
        }
        else if (selected.team === "operations") {
            setTeamIcon(OpsLogo);
        }
    }, [selected])

    return (
        <div className="player-card">

            <div className="player-card-info" >
                <div className="player-top" style={{ zIndex: 1, }}>
                    <div className="headshot-image-container">
                        {selected.imagePath !== "" && <div className="background-shape"></div>}

                        {selected.imagePath !== "" ? (
                            <img className="headshot" src={selected.imagePath} alt="Headshot" />
                        ) : (
                            <div className="headshot-placeholder"></div>
                        )}
                    </div>
                    <div className="player-team">
                        {teamIcon !== "" ? <img className="player-team-icon" src={teamIcon} /> : <div className="headshot"></div>}
                    </div>
                </div>
                {/* Info */}
                <div className="selected-info" >
                    <h1 id="selected-name" className="gamer-text">{selected.name}</h1>
                    <h1 id="selected-role" className="gamer-text">{selected.role}</h1>
                    <h1 id="selected-team" className="gamer-text">{selected.team}</h1>

                    {selected.name && <div className="gamer-graphics" style={{ background: `url(${MetHacksLogo}) center/cover`, }}></div>}
                </div>
            </div>
            {/* Extra Info */}

        </ div>
    )
}

export default Player; 